<template>
    <!-- 投保step1~4 -->
    <v-container class="wrap">
        <!-- Insure -->
        <stepper :step="step" @turn="turnPage"></stepper>
        <div v-if="step==1">
            <v-row no-gutters align="center">
                <v-col cols="1"></v-col>
                <v-col cols="10"><h2 class="h2 my-3">要保人</h2></v-col>
                <v-col cols="1"><v-icon color="primary" @click="save_personal_data">mdi-content-save</v-icon></v-col>
            </v-row>
            <insure-form></insure-form>
            <span class="caption">確認無誤請點下方按鈕新增被保人</span>
            <v-btn @click="save_personal_data()" color="primary" depressed class="mt-1" style="width:80%;">新增被保人</v-btn>
        </div>
        <div v-if="step==2" style="height:90%">
            <insured @lastStep="lastStep" @nextStep="nextStep"></insured>
        </div>
        <div v-if="step==3" style="height:100%;">
            <v-row class="flex-column" justify="space-between" style="height:100%;">
                <v-col>
                <v-row no-gutters align="center">
                    <v-col cols="1"></v-col>
                    <v-col cols="10"><h2 class="h2 my-3">投保內容</h2></v-col>
                    <v-col cols="1"><v-icon color="primary">mdi-content-save</v-icon></v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center" class="mb-5">
                    <p class="mr-3">保單名稱：旺旺友聯產物新旅行綜合保險國內加值型</p>
                </v-row>
                <v-row no-gutters align="center" justify="center" class="mb-2 px-4">
                    <v-col style="text-align:left">
                        <p>身故或失能保險金</p>
                    </v-col>
                    <v-col cols="7">
                        <v-select dense outlined v-model="content.add_value" :items="['100萬','200萬','300萬','500萬','600萬','800萬','1000萬','1500萬','2000萬']" hide-details></v-select>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center" class="px-4">
                    <v-col style="text-align:left">
                        <p>傷害醫療保險金</p>
                    </v-col>
                    <v-col cols="7">
                        <v-select v-model="content.mr_value" :items="content.add_value==''?[]:mr_dict[content.add_value]" dense outlined hide-details></v-select>
                    </v-col>
                </v-row>
                </v-col>
                <v-col cols="5" style="max-width:100%">
                <p class="caption">確認無誤請點擊下方按鈕</p>
                <v-btn @click="lastStep" color="primary" depressed outlined class="mt-1" style="width:80%;">回上一頁</v-btn>
                <v-btn @click="open_web(pdf_url)" color="primary" depressed outlined class="mt-1" style="width:80%;">投保須知</v-btn>
                <v-btn @click="content_submit" color="primary" depressed class="mt-1" style="width:80%;">確認保單資料</v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-if="step==4">
            <v-row no-gutters align="center">
                <v-col cols="1"></v-col>
                <v-col cols="10"><h2 class="h2 my-3">確認保單資料</h2></v-col>
                <v-col cols="1"><v-icon @click="go_pay" color="primary">mdi-content-save</v-icon></v-col>
            </v-row>
            <v-row no-gutters align="center" justify="center">
                <p class="mr-3">保單名稱：旺旺友聯產物新旅行綜合保險國內加值型</p>
            </v-row>
            <insure-data></insure-data>
            <v-btn v-if="$store.getters.insure_status==1" @click="$router.push('/record')" color="primary" depressed outlined class="mt-1" style="width:80%;">回上一頁</v-btn>
            <v-btn v-if="$store.getters.insure_status==0" @click="lastStep" color="primary" depressed outlined class="mt-1" style="width:80%;">回上一頁</v-btn>
            <v-btn v-if="$store.getters.insure_status==0" color="primary" @click="pay_submit" depressed class="mt-1" style="width:80%;">簽名、付款</v-btn>
            <!-- <v-btn color="primary" @click="pay_submit" depressed class="mt-1" style="width:80%;">簽名、付款</v-btn> -->
        </div>
    </v-container>
</template>

<script>
import { mapGetters,mapMutations,mapActions } from 'vuex'
var host_url = process.env.VUE_APP_HOST_URL
import Insured from '../components/Insured.vue';
import InsureData from '../components/InsureData.vue';
import InsureForm from '../components/InsureForm.vue';
import Stepper from '../components/Stepper.vue';

export default {
    components:{InsureForm, Stepper, Insured, InsureData},
    data(){
        return{
            step: 1,
            personal_data_id: -1,
            mr_dict: {
                '100萬': ['3萬','10萬'],
                '200萬': ['3萬','10萬','20萬'],
                '300萬': ['3萬','10萬','20萬'],
                '500萬': ['3萬','10萬','50萬'],
                '600萬': ['3萬','10萬','60萬'],
                '800萬': ['3萬','10萬','80萬'],
                '1000萬': ['3萬','10萬','100萬'],
                '1500萬': ['3萬','10萬','100萬','150萬'],
                '2000萬': ['3萬','10萬','100萬','200萬'],
            },
            content: {
                add_value: '',
                mr_value: '',
            },
            pdf_url: 'http://test.mib.com.tw/file/%E6%8A%95%E4%BF%9D%E4%BA%BA%E9%A0%88%E7%9F%A5%E8%A6%81%E4%BF%9D%E6%9B%B8%E5%A1%AB%E5%AF%AB%E5%8F%8A%E5%80%8B%E8%B3%87%E5%91%8A%E7%9F%A5%E6%9B%B8Mib.pdf',
        }
    },
    computed:{
        ...mapGetters([
        'idNum',
        'password',
        'chName',
        'enName',
        'activePicker',
        'birth',
        'gender',
        'country',
        'otherCountry',
        'mobile',
        'tel',
        'year',
        'month',
        'date',
        'years',
        'months',
        'dates',
        
        'city',
        'area',
        'address',

        'insure_content_id',
        ]),
    },      
    mounted(){
        console.log("insure mounted 112~~~~")
        this.get_last_step()
        this.get_content()
    },
    methods:{
        ...mapActions(['setYearsMons', 'resetAreas', 'resetZip']),
        ...mapMutations([
            'setInsureContentId',
            'setYear',
            'setMonth',
            'setDate',
            'setDays',
            'setIdNum',
            'setPassword',
            'setChName',
            'setEnName',
            'setActivePicker',
            'setBirth',
            'setMenu',
            'setGender',
            'setCountry',
            'setOtherCountry',
            'setMobile',
            'setTel',

            'setCity',
            'setArea',
            'setAddress',
        ]),
        open_web(url){
            window.open(url, '_blank');
        },
        go_pay(){
            // alert('clicker')
            // let url = 'http://test.mib.com.tw/AdminMib/TravIns1/TravIns_PayPage.jsp'
            // liff.openWindow({
            //     url: 'google.com',
            //     external: true
            // })

            window.open("http://test.mib.com.tw/AdminMib/TravIns1/TravIns_PayPage.jsp", '_blank');

            // var someCallback = function(url) {
            // };

        },
        get_content(){
            axios.get(host_url + '/insure/content_set/?code='+this.$store.getters.insure_code)
            .then(response => {
                var tmp = response.data
                console.log("tmp:")
                console.log(tmp)
                if(tmp.status == 200){
                    this.content = tmp.content
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        content_submit(){
            let post_data = {
                content: this.content,
                insure_code: this.$store.getters.insure_code
            }
            axios.post(host_url + '/insure/content_set/', post_data)
            .then(response => {
                var tmp = response.data
                console.log("tmp:")
                console.log(tmp)
                if(tmp.status == 200){
                    this.nextStep()
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        pay_submit(){
            if(!this.$store.getters.insure_check_agree1){
                alert('尚未同意 本人(要保人)已受告知並了解所投保商品之重要內容及投保須知等相關事宜')
                return
            }
            if(!this.$store.getters.insure_check_agree2){
                alert('尚未同意 已閱讀要(被)保險人聲明事項')
                return
            }

            if(!this.$store.getters.insure_data_check_related_person_status){
                alert('被保人資料尚未完成 請再確認')
                return
            }
            var mywin = window.open("about:blank", "redirect");
            let post_data = {insure_code: this.$store.getters.insure_code}
            axios.post(host_url + '/insure/pay_submit/', post_data)
            .then(response => {
                var tmp = response.data
                console.log(tmp)
                if(tmp.status == 200){
                    mywin.open('http://test.mib.com.tw/AdminMib/TravIns1/TravIns_PayPage.jsp', "redirect");
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        get_last_step(){
            let post_data = {insure_code: this.$store.getters.insure_code}
            axios.post(host_url + '/insure/get_last_step/', post_data)
            .then(response => {
                var tmp = response.data
                console.log(tmp)
                if(tmp.status == 200){
                    this.step = tmp.step
                    if(this.step == 1)
                        this.get_personal_data()
                    else
                        this.setInsureContentId(tmp.insure_content_id)
                    
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        // new_insure(personal_data_id){
        //     let post_data = {insure_id: this.$route.params.id, personal_data_id: personal_data_id}
        //     axios.post(host_url + '/insure/new_insure/', post_data)
        //     .then(response => {
        //         var tmp = response.data
        //         console.log(tmp)
        //         if(tmp.status == 200){
        //             this.setInsureContentId(tmp.insure_content_id)
        //             this.nextStep()
        //         }
        //     })
        //     .catch((error)=> {
        //         console.log(error);
        //     });
        // },
        get_personal_data(){
            if(this.step != 1)
                return

            let post_data = {
                auth_user: true
            }
            axios.post(host_url + '/insure/get_personal_data/', post_data)
            .then(response => {
                var tmp = response.data
                console.log("tmp:")
                console.log(tmp)
                if(tmp.status == 200){
                    let person = tmp.person
                    this.personal_data_id = person.id
                    this.setChName(person.name)
                    this.setEnName(person.en_name)
                    this.setIdNum(person.idNum)
                    this.setYear(person.year)
                    this.setMonth(person.month)
                    this.setDate(person.date)
                    this.setGender(person.gender)
                    this.setCountry(person.country)
                    this.setMobile(person.mobile)
                    this.setTel(person.tel)

                    this.setCity(person.city)
                    this.resetAreas()
                    this.setArea(person.area)
                    this.resetZip()
                    this.setAddress(person.address)
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },

        save_personal_data(){
            console.log('In save personal data ~~~ 238')
            let post_data = {
                personal_data_id: this.personal_data_id,
                auth_user: true,
                insure_id: this.$store.getters.insure_id,
                insure_code: this.$store.getters.insure_code,
                personal_data: {
                    'chName': this.chName,
                    'enName': this.enName,
                    'idNum': this.idNum,

                    'year': this.year,
                    'month': this.month,
                    'date': this.date,

                    'gender': this.gender,
                    'country': this.country,

                    'mobile': this.mobile,
                    'tel': this.tel,

                    'city': this.city,
                    'area': this.area,
                    'address': this.address
                },
            }
            axios.post(host_url + '/insure/save_personal_data/', post_data)
            .then(response => {
                var tmp = response.data
                if(tmp.status == 200){
                    this.setInsureContentId(tmp.insure_content_id)
                    this.nextStep()
                }
                console.log(tmp)
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        lastStep(){
            this.step -= 1
            this.get_personal_data()
            this.record_step()
        },
        nextStep(){
            this.step += 1
            this.get_personal_data()
            this.record_step()
        },
        record_step(){
            let post_data = {
                insure_content_id: this.$store.getters.insure_content_id, 
                step: this.step
            }
            axios.post(host_url + '/insure/update_step/', post_data)
            .then(response => {
                var tmp = response.data
                console.log(tmp)
                if(tmp.status == 200){
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        turnPage(val){
            this.step = parseInt(val)
            this.record_step()
        },
    }
}
</script>
<style scoped lang="scss">

</style>