<template>
<!-- 被保人step2-1~2-2 -->
    <v-col :class="{'pa-0': !next}">
        <!-- InsureCreate {{insure_related_id}} -->
        <v-row no-gutters align="center">
            <v-col cols="1"><v-icon color="primary" :disabled="true">mdi-share</v-icon></v-col>
            <v-col cols="10"><h2 class="h2 my-3">被保險人{{insuredNum}}</h2></v-col>
            <v-col cols="1"><v-icon color="primary" @click="leave_save_dialog=true">mdi-close</v-icon></v-col>
        </v-row>
        <div v-if="!next">
            <v-row no-gutters align="center">
                <v-col>
                    <insure-form></insure-form>
                </v-col>
            </v-row>
            <!-- <v-btn @click="$emit('close')" color="primary" outlined class="mr-3 pa-5">回上一頁</v-btn> -->
            <!-- <v-btn @click="next=true;scroll2Top();" color="primary" depressed style="width:80%;">身故受益人設定</v-btn> -->
            <v-btn @click="save_personal_data(); get_death_benefit()" color="primary" depressed style="width:80%;">身故受益人設定</v-btn>
        </div>
        <div v-if="next" class="next">
            <h4 class="primary--text mb-3">身故受益人</h4>
            <v-select v-model="qdict.benefitPerson" :items="items" dense outlined hide-details placeholder="請選擇繼承人類型" class="mb-8"></v-select>
            <div v-if="qdict.benefitPerson==1" >
                <div v-for="(item,idx) in heirList" :key="idx">
                    <h4 class="primary--text mb-3">第{{idx+1}}位</h4>
                    <v-text-field v-model="item.name" single-line dense outlined>
                        <template v-slot:prepend>
                            <div class="nowrap mt-1 preText">姓名&emsp;&emsp;&emsp;</div>
                        </template>
                    </v-text-field>
                    <v-text-field v-model="item.idNum" single-line dense outlined>
                        <template v-slot:prepend>
                            <div class="nowrap mt-1 preText">身份證字號</div>
                        </template>
                    </v-text-field>
                    <v-text-field v-model="item.tel" single-line dense outlined>
                        <template v-slot:prepend>
                            <div class="nowrap mt-1 preText">聯絡電話&emsp;</div>
                        </template>
                    </v-text-field>
                    <v-text-field v-model="item.address" single-line dense outlined>
                    <template v-slot:prepend>
                        <div class="nowrap mt-1 preText">聯絡地址&emsp;</div>
                    </template>
                    </v-text-field>
                    <v-text-field v-model="item.related" single-line dense outlined>
                    <template v-slot:prepend>
                        <div class="nowrap mt-1 preText">與被保人之關係</div>
                    </template>
                    </v-text-field>
                    <v-row class="align-baseline flex-nowrap" no-gutters>
                        <div class="nowrap mt-1 flex-grow-1">繼承設定</div>
                        <v-col cols="5">
                            <v-select v-model="item.inheit_order" :items="heirList_idx_list" item-text="" item-value="id" hide-details outlined dense placeholder="繼承順位" class="pr-3 pl-1"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="item.ratio" single-line dense outlined placeholder="繼承比例"></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <div class="text-center mb-10">
                    <v-btn @click="addHeir" class="px-5 py-4" outlined color="primary" small rounded>
                        <v-icon small>mdi-plus</v-icon>
                        <span style="font-size:14px;">受益人</span> 
                    </v-btn>
                </div>
            </div>
            <h4 class="primary--text mb-3">應告知事項</h4>
            <p>就本次旅行，被保險人是否已投保其他旅行平安保險：</p>
            <v-radio-group v-model="qdict.ans1">
                <v-row align="center" no-gutters>
                    <v-col class="py-0 mr-2" cols="2">
                        <v-radio
                        value="yes"
                        label="是"
                        hide-details
                        class="shrink mr-2 mt-0"
                        ></v-radio>
                    </v-col>
                    <v-col class="py-0 mr-2" cols="4">
                        <v-text-field
                        v-model="qdict.ans1_company"
                        :disabled="qdict.ans1=='no'"
                        >
                            <template v-slot:label>
                                <div style="font-size:12px;">
                                    保險公司名稱
                                </div>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col class="py-0 mr-2">
                        <v-text-field v-model="qdict.ans1_money" :disabled="qdict.ans1=='no'"                        >
                            <template v-slot:label>
                                <div style="font-size:12px;">
                                    保險金額
                                </div>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col class="py-0">
                        <v-radio
                        value="no"
                        label="否"
                        hide-details
                        class="shrink mr-2 mt-0"
                        ></v-radio>
                    </v-col>
                </v-row>
            </v-radio-group>
            <p>被保險人是否已投保其他商業實支實付型傷害醫療保險或實支實付型醫療保險：</p>
            <v-radio-group v-model="qdict.ans2">
                <v-row align="center" no-gutters>
                    <v-col class="py-0 mr-2" cols="2">
                        <v-radio
                        value="yes"
                        label="是"
                        hide-details
                        class="shrink mr-2 mt-0"
                        ></v-radio>
                    </v-col>
                    <v-col class="py-0 mr-2" cols="4">
                        <v-text-field v-model="qdict.ans2_company" :disabled="qdict.ans2=='no'">
                            <template v-slot:label>
                                <div style="font-size:12px;">
                                    保險公司名稱
                                </div>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col class="py-0 mr-2">
                        <v-text-field v-model="qdict.ans2_money" :disabled="qdict.ans2=='no'">
                            <template v-slot:label>
                                <div style="font-size:12px;">
                                    保險金額
                                </div>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col class="py-0">
                        <v-radio
                        value="no"
                        label="否"
                        hide-details
                        class="shrink mr-2 mt-0"
                        ></v-radio>
                    </v-col>
                </v-row>
            </v-radio-group>
            <p>被保險人目前是否受有監護宣告：</p>
            <v-radio-group v-model="qdict.ans3">
                <v-row align="center" no-gutters>
                    <v-col class="py-0 mr-4" cols="2">
                        <v-radio
                        value="yes"
                        label="是"
                        hide-details
                        class="shrink mr-2 mt-0"
                        ></v-radio>
                    </v-col>
                    <v-col>
                        <p class="caption ma-0">請提供相關證明文件</p>
                    </v-col>
                    <v-col class="py-0">
                        <v-radio
                        value="no"
                        label="否"
                        hide-details
                        class="shrink mr-2 mt-0"
                        ></v-radio>
                    </v-col>
                </v-row>
            </v-radio-group>
            <div class="next_action mt-3">
                <v-btn @click="next=false;scroll2Top();" color="primary" outlined class="mr-3 pa-5">回上一頁</v-btn>
                <!-- todo: save -->
                <v-btn @click="death_benefit_save(1)" depressed color="primary" class="pa-5">完成填寫</v-btn>
            </div>
        </div>

        <v-dialog v-model="leave_save_dialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">提示</v-card-title>
        
                <v-card-text class="pt-3" style="font-size: 18px;">您即將離開被保人資料編輯畫面，是否儲存後離開?</v-card-text>
        
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="primary" @click="$emit('close')" outlined>取消</v-btn> -->
                    <v-btn color="primary" @click="leave_save_dialog=false" outlined>取消</v-btn>
                    <v-btn color="primary" @click="death_benefit_save(0)">是</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-col>
</template>
<script>
import { mapGetters,mapMutations,mapActions } from 'vuex'
var host_url = process.env.VUE_APP_HOST_URL
import InsureForm from './InsureForm.vue'
export default {
    props:{
        add:{
            default:false
        },
        insuredPeple:{
            type: Array
        },
        personal_data_id:{
            type: Number,
            default:-1
        },
        insure_related_id_: {
            type: Number,
            default:-1
        },
    },
    components:{ InsureForm },
    data(){
        return{
            qdict: {
                benefitPerson: null,
                ans1: 'no',
                ans1_company: '',
                ans1_money: '',
                ans2: 'no',
                ans2_company: '',
                ans2_money: '',
                ans3: 'no'
            },
            next: false,
            items:[ {value:1 ,text:'指定繼承人'},{value:2 ,text:'法定繼承人'}],
            // heirList:[{name: '', idNum: '', tel: '', address: '', related: '', inheit_type: '', ratio: ''}],
            heirList:[],

            inheit_type_list: [{title: 'test1', id: 1}],

            leave_save_dialog: false,
            insure_related_id: -1,
        }
    },
    computed:{
        heirList_idx_list(){
            let index_list = []
            let idx = 1
            for(let a of this.heirList){
                index_list.push(idx)
                idx += 1
            }
            return index_list
        },
        insuredNum(){
            return this.insuredPeple.length+1
        },
        ...mapGetters([
        'idNum',
        'password',
        'chName',
        'enName',
        'activePicker',
        'birth',
        'gender',
        'country',
        'otherCountry',
        'mobile',
        'tel',
        'year',
        'month',
        'date',
        'years',
        'months',
        'dates',

        'city',
        'area',
        'address',

        'insure_content_id',
        ]),
    },
    mounted(){
        this.insure_related_id = this.insure_related_id_
        // new
        if(this.personal_data_id == -1){

        }
        // edit
        else{
            this.get_personal_data()
        }
    },
    methods:{
        get_death_benefit(){
            let post_data = {
                insure_related_id: this.insure_related_id
            }
            axios.post(host_url + '/insure/get_death_benefit/', post_data)
            .then(response => {
                var tmp = response.data
                console.log("tmp:")
                console.log(tmp)
                if(tmp.status == 200){
                    this.qdict = tmp.qdict
                    this.heirList = tmp.heirList
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        ...mapMutations([
            'setYear',
            'setMonth',
            'setDate',
            'setDays',
            'setIdNum',
            'setPassword',
            'setChName',
            'setEnName',
            'setActivePicker',
            'setBirth',
            'setMenu',
            'setGender',
            'setCountry',
            'setOtherCountry',
            'setMobile',
            'setTel',
            'setCity',
            'setArea',
            'setAddress',
        ]),
        get_personal_data(){
            let post_data = {
                personal_data_id: this.personal_data_id
            }
            axios.post(host_url + '/insure/get_personal_data/', post_data)
            .then(response => {
                var tmp = response.data
                console.log("tmp:")
                console.log(tmp)
                if(tmp.status == 200){
                    let person = tmp.person
                    this.setChName(person.name)
                    this.setEnName(person.en_name)
                    this.setIdNum(person.idNum)
                    this.setYear(person.year)
                    this.setMonth(person.month)
                    this.setDate(person.date)
                    this.setGender(person.gender)
                    this.setCountry(person.country)
                    this.setMobile(person.mobile)
                    this.setTel(person.tel)
                    this.setArea(person.area)
                    this.setAddress(person.address)
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        death_benefit_save(status){
            if(this.next){
                let post_data = {
                    insure_related_id: this.insure_related_id,
                    qdict: this.qdict,

                    heirList: this.heirList,
                    status: status,
                }
                axios.post(host_url + '/insure/death_benefit_save/', post_data)
                .then(response => {
                    var tmp = response.data
                    console.log(tmp)
                    if(tmp.status == 200){
                        this.$emit('close')
                    }
                })
                .catch((error)=> {
                    console.log(error);
                })
            }
            else{
                this.save_personal_data()
            }
        },
        death_benefit_set(personal_data_id_){
            if(this.insure_related_id != -1)
                return 

            let post_data = {
                insure_content_id: this.$store.getters.insure_content_id,
                personal_data_id: personal_data_id_,
            }
            axios.post(host_url + '/insure/death_benefit_set/', post_data)
            .then(response => {
                var tmp = response.data
                if(tmp.status == 200){
                    this.insure_related_id = tmp.insure_related_id
                }
                console.log(tmp)
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        save_personal_data(){
            console.log('In save_personal_data~~~ 404')
            let post_data = {
                personal_data_id: this.personal_data_id,
                personal_data: {
                    'chName': this.chName,
                    'enName': this.enName,
                    'idNum': this.idNum,

                    'year': this.year,
                    'month': this.month,
                    'date': this.date,

                    'gender': this.gender,
                    'country': this.country,

                    'mobile': this.mobile,
                    'tel': this.tel,

                    'city': this.city,
                    'area': this.area,
                    'address': this.address
                },
            }
            axios.post(host_url + '/insure/save_personal_data/', post_data)
            .then(response => {
                var tmp = response.data
                if(tmp.status == 200){
                    if(this.insure_related_id == -1)
                        this.death_benefit_set(tmp.personal_data_id)

                    if(this.leave_save_dialog){
                        this.leave_save_dialog = false
                        this.$emit('close')
                    }
                    else{
                        this.next = true
                        this.scroll2Top()
                    }
                }
                console.log(tmp)
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        save(){
            // todo: clean
            this.next = false
            // todo: api
            this.$emit('close')
        },
        scroll2Top(){
            window.scrollTo(0,0)
        },
        addHeir(){
            this.heirList.push({
                name: '',
                idNum: '',
                tel: '',
                address: '',
                related: '',
                inheit_type: -1,
                ratio: '',
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.next{
    text-align: left;
    &_action{
        text-align: center;
    }
}
</style>