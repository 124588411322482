<template>
<!-- 首頁/暫存保單 -->
    <v-container class="wrap">
        <v-row class="my-2" align="center">
            <v-col cols="2">
                <v-icon @click="$router.push('/')" large>mdi-chevron-left</v-icon>
            </v-col>
            <div class="h2 text-center">暫存保單</div> 
        </v-row>
        <v-col cols="8" style="max-width: 100%">
            <v-card class="insuredCard" outlined v-for="item in insuranceList" :key="item.id" @click="get_insure(item)"            >
                <v-row align="center" no-gutters justify="space-between">
                    <v-col cols="8">
                        <v-card-text class="text-left" style="height:100%">
                        <h3 class="mb-3">{{item.title}}</h3>
                        <div class="d-flex align-center">
                        <p class="mb-0">{{item.subTitle}}</p> 
                        </div>
                        </v-card-text>
                    </v-col>
                    <v-col cols="2">
                        <v-icon large color="primary" class="mr-3">mdi-pencil</v-icon>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-container>
</template>

<script>
var host_url = process.env.VUE_APP_HOST_URL

export default {
    name: 'TmpStorage',
    data(){
        return{
            insuranceList:[
            // {
            //     id:0,
            //     title:'南山人壽旅行平安險',
            //     subTitle: '填寫階段:被保人2資料',
            // },
            // {
            //     id:1,
            //     title:'新光產物旅行平安險',
            //     subTitle: '填寫階段:要保人資料',
            // }
            ]
        }
    },
    mounted(){
        this.get_insure_list()
    },
    methods:{
        get_insure(item){
            console.log('In get_insure')
            console.log("item", item)

            this.$store.commit('setInsureCode', item.insure_code)
            this.$store.commit('setInsureContentId', item.id)
            this.$store.commit('setInsureId', item.insure_id)
            this.$router.push(`/insure`)
        },
        get_insure_list(){
            axios.get(host_url + '/insure/get_insure_list/')
            .then(response => {
                var tmp = response.data
                console.log(tmp)
                if(tmp.status==200){
                    this.insuranceList = tmp.insure_content_list
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.insuredCard{
    border:1px solid #aaa;
    margin-bottom: 16px;
    h3{
        font-size: 18px;
        font-weight: bold;
    }
}
</style>