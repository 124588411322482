<template>
    <!-- 首頁/被保人資料 -->
    <v-container class="wrap">
        <div v-if="!edit">
            <v-row class="my-2" align="center">
                <v-col cols="2">
                    <v-icon @click="$router.push('/')" large>mdi-chevron-left</v-icon>
                </v-col>
                <div class="h2 text-center">被保人資料</div> 
            </v-row>
            <v-col cols="8" style="max-width: 100%">
                <v-card class="insuredCard" outlined v-for="item in insured_people_list" :key="item.id">
                <v-row align="center" no-gutters justify="space-between">
                    <v-col cols="8">
                        <v-card-text class="text-left" style="height:100%">
                        <h3>{{item.name}}</h3>
                        </v-card-text>
                    </v-col>
                    <v-col cols="2">
                        <v-icon @click="get_personal_data(item.id)" large color="primary" class="mr-3">mdi-pencil</v-icon>
                    </v-col>
                </v-row>
                </v-card>
            </v-col>
            <v-btn color="primary" depressed :disabled="true">分享被保人填寫連結</v-btn>
        </div>
        <div v-else>
            <insure-form></insure-form>
            <v-btn @click="save_personal_data" color="primary" depressed class="mt-1" style="width:80%;">儲存</v-btn>
        </div>
    </v-container>
</template>

<script>
var host_url = process.env.VUE_APP_HOST_URL
import InsureForm from '../components/InsureForm.vue';

import { mapGetters,mapMutations} from 'vuex'
export default {
    components:{InsureForm},
    name: 'TmpStorage',
    data(){
        return{
            insured_people_list:[],
            personal_data_id: -1,
            edit: false,
        }
    },
    mounted(){
        this.get_insured_peple_list()
    },
    computed:{
        ...mapGetters([
        'idNum',
        'password',
        'chName',
        'enName',
        'activePicker',
        'birth',
        'gender',
        'country',
        'otherCountry',
        'mobile',
        'tel',
        'year',
        'month',
        'date',
        'years',
        'months',
        'dates',
        
        'city',
        'area',
        'address',
        ]),
    },
    methods: {
        ...mapMutations([
            'setYear',
            'setMonth',
            'setDate',
            'setDays',
            'setIdNum',
            'setPassword',
            'setChName',
            'setEnName',
            'setActivePicker',
            'setBirth',
            'setMenu',
            'setGender',
            'setCountry',
            'setOtherCountry',
            'setMobile',
            'setTel',
            'setCity',
            'setArea',
            'setAddress',
        ]),
        get_insured_peple_list(){
            axios.get(host_url + '/insure/get_insured_peple_list/')
            .then(response => {
                var tmp = response.data
                if(tmp.status == 200){
                    this.insured_people_list = tmp.res
                    this.edit = false
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        get_personal_data(id){
            this.personal_data_id = id
            let post_data = {
                personal_data_id: this.personal_data_id
            }
            axios.post(host_url + '/insure/get_personal_data/', post_data)
            .then(response => {
                var tmp = response.data
                console.log("tmp:")
                console.log(tmp)
                if(tmp.status == 200){
                    let person = tmp.person
                    this.setChName(person.name)
                    this.setEnName(person.en_name)
                    this.setIdNum(person.idNum)
                    this.setYear(person.year)
                    this.setMonth(person.month)
                    this.setDate(person.date)
                    this.setGender(person.gender)
                    this.setCountry(person.country)
                    this.setMobile(person.mobile)
                    this.setTel(person.tel)
                    this.setCity(person.city)
                    this.setArea(person.area)
                    this.setAddress(person.address)
                    
                    this.edit = true
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        save_personal_data(){
            console.log('In save_personal_data ~~~ 146')
            let post_data = {
                personal_data_id: this.personal_data_id,
                personal_data: {
                    'chName': this.chName,
                    'enName': this.enName,
                    'idNum': this.idNum,

                    'year': this.year,
                    'month': this.month,
                    'date': this.date,

                    'gender': this.gender,
                    'country': this.country,

                    'mobile': this.mobile,
                    'tel': this.tel,

                    'city': this.city,
                    'area': this.area,
                    'address': this.address
                },
            }
            axios.post(host_url + '/insure/save_personal_data/', post_data)
            .then(response => {
                var tmp = response.data
                if(tmp.status == 200){
                    this.get_insured_peple_list()
                }
                console.log(tmp)
            })
            .catch((error)=> {
                console.log(error);
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.insuredCard{
    border:1px solid #aaa;
    margin-bottom: 16px;
    .row{
        height: 80px;
    }
    h3{
        font-size: 18px;
        font-weight: bold;
    }
}
</style>