import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import Terms from '../views/Terms.vue'
import Insure from '../views/Insure.vue'
import TmpStorage from '../views/TmpStorage.vue'
import InsuredList from '../views/InsuredList.vue'
import Record from '../views/Record.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signUp',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/insure/:id?',
    name: 'Insure',
    component: Insure
  },
  {
    path: '/tmpStorage',
    name: 'TmpStorage',
    component: TmpStorage
  },
  {
    path: '/insuredList',
    name: 'InsuredList',
    component: InsuredList
  },
  {
    path: '/record',
    name: 'Record',
    component: Record
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
