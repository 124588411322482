<template>
<!-- 首頁/投保紀錄 -->
    <v-container class="wrap">
        <v-row class="my-2" align="center">
            <v-col cols="2">
                <v-icon @click="$router.push('/')" large>mdi-chevron-left</v-icon>
            </v-col>
            <div class="h2 text-center">投保紀錄</div> 
        </v-row>
        
        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="0" transition="scale-transition" offset-y min-width="auto" max-width="350" >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dateRangeText" outlined label="時間搜尋" :clearabl="true" readonly v-bind="attrs" class="px-3" hide-details v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="dates" locale="zh-cn" no-title range full-width style="width:100%; height:420px;" id="dateMenu">
            <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dates=[]">Clear</v-btn>
                <v-btn text color="primary" @click="menu2=false; get_insure_history()">OK</v-btn>
            </v-date-picker>
        </v-menu>


        <v-col cols="8" style="max-width: 100%">
            <v-card
            class="insuredCard"
            outlined
            v-for="item in insure_history"
            :key="item.id"
            >
            <v-row align="baseline" no-gutters justify="space-between">
                <v-col cols="8">
                    <v-card-text class="text-left" style="height:100%">
                    <h3 class="mb-2">{{item.title}}</h3>
                    <div class="d-flex align-center">
                    <p class="mb-0 primary--text">{{item.sub_title}}</p> 
                    </div>
                    </v-card-text>
                </v-col>
                <v-col cols="4" class="insuredCard_action">
                    <p class="mb-2 grey--text text--darken-1">{{item.created_at.slice(0,10)}}</p>
                    <p class="ma-0">
                        <span @click="open_web('http://test.mib.com.tw/AdminMib/TravIns1/TravIns_ChanQuerry.html?ProId=12')">變更保單 </span>
                        <span @click="open_web('http://test.mib.com.tw/AdminMib/TravIns1/TravIns_Querry.html?ProId=12')"> 查看</span>
                    </p>
                </v-col>
            </v-row>
            </v-card>
        </v-col>
    </v-container>
</template>

<script>
var host_url = process.env.VUE_APP_HOST_URL
export default {
    name: 'TmpStorage',
    data(){
        return{
            menu2: false,
            insure_history:[
            //     {
            //     id:0,
            //     title:'旺旺友聯旅平險-加值型',
            //     sub_title: '保單資料已送出',
            // },{
            //     id:1,
            //     title:'旺旺友聯旅平險-加值型',
            //     sub_title: '保單資料已送出',
            // }
            ],
            dates: [],

        }
    },
    computed: {
        dateRangeText () {
            console.log(this.dates)
            console.log(this.dates)
            console.log(this.dates)
            return this.dates.join(' ~ ')
        },
    },
    mounted(){
        this.get_insure_history()
    },
    methods: {
        open_web(url){
            window.open(url, '_blank');
        },
        go_history(item){
            this.$store.commit('setInsureCode', item.insure_code)
            this.$store.commit('setInsureContentId', item.id)
            this.$store.commit('setInsureId', item.insure_id)
            this.$router.push(`/insure`)
        },
        get_insure_history(){
            let post_data = {
                dates: this.dates
            }
            axios.post(host_url + '/insure/get_insure_history/', post_data)
            .then(response => {
                var tmp = response.data
                console.log(tmp)
                if(tmp.status==200){
                    this.insure_history = tmp.insure_history
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.insuredCard{
    border:1px solid #aaa;
    margin-bottom: 16px;
    h3{
        font-size: 16px;
        font-weight: bold;
    }
    &_action{
        white-space: nowrap;
        text-align: right;
        p{
        padding-right: 16px;
        }
    }
}
// .v-menu__content.theme--light.menuable__content__active{
//   left:0 !important;
//   max-width: 100% !important;
// }

</style>
<style>
.v-date-picker-table.v-date-picker-table--date.theme--light table button{
  padding: 24px;
}
</style>