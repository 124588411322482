import addressJson from '../../assets/json/address.json'

const state = {
    idNum: '',
    password: '',
    chName: '',
    enName: '',
    activePicker: null,
    birth: null,
    gender: '',
    country: '',
    otherCountry:'',
    mobile: '',
    tel:'',
    year: null,
    month: null,
    date: null,
    years: [],
    months: [],
    dates: [],
    city: '',
    area: '',
    areas: [],
    address: '',
    zip: '',
};

//view透過getters拿到states
const getters = {
    idNum: (state) => (state.idNum),
    password: (state) => (state.password),
    chName: (state) => (state.chName),
    enName: (state) => (state.enName),
    activePicker: (state) => (state.activePicker),
    birth: (state) => (state.birth),
    gender: (state) => (state.gender),
    country: (state) => (state.country),
    otherCountry: (state) => (state.otherCountry),
    mobile: (state) => (state.mobile),
    tel: (state) => (state.tel),
    year: (state) => (state.year),
    month: (state) => (state.month),
    date: (state) => (state.date),
    years: (state) => (state.years),
    months: (state) => (state.months),
    dates: (state) => (state.dates),
    city: (state) => (state.city),
    area: (state) => (state.area),
    areas: (state) => (state.areas),
    address: (state) => (state.address),
    zip: (state) => (state.zip),
};

//api request, response 後呼叫mutations
const actions = {
    setYearsMons({ commit }){
        let nowY  = new Date().getFullYear()-1911
        let newYear = []
        let newMon = []
        for(let i = 10; i<=nowY; i++){
            newYear.unshift(i)
        }
        commit('setYears', newYear)
        for(let i = 1; i<=12; i++){
            newMon.push(i)
        }
        commit('setMonths', newMon)
    },
    resetAreas({ commit, state }){
        console.log("In reset Areas11", state.city)
        var areas = []
        addressJson.forEach(el=>{
            if(state.city == el.City && !areas.includes(el.Area)){
                areas.push(el.Area)
            }
        })
        console.log("In reset Areas22", areas)
        commit('setAreas', areas)
    },
    resetZip({ commit, state }){
        console.log("In set Zip", state.area)
        let zip_ = addressJson.find(el=>{
            return el.City==state.city && el.Area==state.area
        }).Zip5.slice(0,3)
        commit("setZip", zip_)
    },
    resetPerson({ commit, state}){
        commit('setChName', '')
        commit('setEnName', '')
        commit('setIdNum', '')
        commit('setYear', '')
        commit('setMonth', '')
        commit('setDate', '')

        commit('setGender', '')
        commit('setCountry', '')
        commit('setMobile', '')
        commit('setTel', '')

        commit('setCity', '')
        commit('setArea', '')
        commit('setAddress', '')
    },
};

//獲得data,改變states
const mutations = {
    setYears:(state, years) => (state.years = years),
    setMonths:(state, months) => (state.months = months),
    setDays(state,payload){
        let newDays = []
        let days = new Date(payload.year,payload.month,0).getDate()
        for(let i=1; i<=days; i++){
            newDays.push(i)
        }
        state.dates = newDays
    },
    setYear:(state, year) => (state.year = year),
    setMonth:(state, month) => (state.month = month),
    setDate:(state, date) => (state.date = date),
    setIdNum:(state, idNum) => (state.idNum = idNum),
    setPassword:(state, password) => (state.password = password),
    setChName:(state, chName) => (state.chName = chName),
    setEnName:(state, enName) => (state.enName = enName),
    setActivePicker:(state, activePicker) => (state.activePicker = activePicker),
    setBirth:(state, birth) => (state.birth = birth),
    setGender:(state, gender) => (state.gender = gender),
    setCountry:(state, country) => (state.country = country),
    setOtherCountry:(state, otherCountry) => (state.otherCountry = otherCountry),
    setMobile:(state, mobile) => (state.mobile = mobile),
    setTel:(state, tel) => (state.tel = tel),
    setCity:(state, city) => (state.city = city),
    setArea:(state, area) => (state.area = area),
    setAreas:(state, areas) => (state.areas = areas),
    setAddress:(state, address) => (state.address = address),
    setZip:(state, tmp) => (state.zip = tmp),
};

export default {
    state,
    getters,
    actions,
    mutations,
};