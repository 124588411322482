<template>
<!-- 投保step2-0 -->
    <div style="height:100%">
        <!-- Insured +++ {{insure_related_id}} -->
        <div v-if="!add" class="mx-auto" style="height:100%">
            <v-row class="d-flex flex-column justify-space-between mx-auto" style="height:100%; width:90%;" no-gutters>
                <v-col class="mx-auto mb-4" style="max-width: 100%">
                    <h2 class="h2 my-3">被保險人</h2>
                    <v-row no-gutters align="center">
                        <v-btn @click="new_insured_person" outlined class="mr-3 pa-2">新增</v-btn>
                        <v-col>
                            <v-select v-model="insured_person" :items="insured_people_list" @change="select_insured_person" dense item-value="id" item-text="name" outlined hide-details placeholder="請選擇被保人"></v-select>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="8" style="max-width: 100%">
                    <v-card class="insuredCard" outlined v-for="(item,idx) in insuredPeple" :key="item.id" @click="selectInsured(item)">
                        <v-row align="center">
                            <v-col cols="8">
                            <v-card-text class="text-left" style="height:100%">
                                    <!-- <h3 class="mb-3">被保人{{idx+1}}:{{item.name}} === {{item.id}}</h3> -->
                                    <h3 class="mb-3">被保人{{idx+1}}:{{item.name}}</h3>
                                    <div v-if="item.status" class="d-flex align-center">
                                    <v-icon color="primary" class="mr-2">mdi-check-circle</v-icon><p class="mb-0 primary--text">已完成</p> 
                                    </div>
                                    <div v-else class="d-flex align-center">
                                    <v-icon color="error" class="mr-2">mdi-alert-circle</v-icon><p class="mb-0 error--text">尚未完成</p> 
                                    </div>
                            </v-card-text>
                            </v-col>
                            <v-col class="insuredCard_icon">
                                <v-icon @click="personal_data_id=item.personal_data_id; insure_related_id=item.id; edit(item)" large color="primary" class="mr-3">mdi-pencil</v-icon>
                                <v-icon @click="remove(item.id)" large>mdi-delete</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col class="mt-5">
                    <v-btn @click="$emit('lastStep')" color="primary" depressed outlined class="mt-1 mr-3">回上一頁</v-btn>
                    <v-btn @click="$emit('nextStep')" color="primary" depressed class="mt-1">投保內容</v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-if="add">
            <insured-create @close="add=false; get_insured_related();" :insuredPeple="insuredPeple" :insure_related_id_="insure_related_id" :personal_data_id="personal_data_id"></insured-create>
        </div>
    </div>
</template>
<script>
var host_url = process.env.VUE_APP_HOST_URL
import InsuredCreate from './InsuredCreate.vue'
export default {
    components:{InsuredCreate},
    data(){
        return{
            add: false,
            insuredPeple:[
                // {id:1,name:'王曉明',status:1},
                // {id:2,name:'王天天',status:0}
            ],
            //0:未完成 1：完成
            insuredPerson: '',
            personal_data_id: -1,
            insure_related_id: -1,

            insured_people_list: [],
            insured_person: '',
        }
    },
    mounted(){
        console.log('In insured mounted~~~')
        this.get_insured_related()
        this.get_insured_peple_list()
    },
    methods: {
        select_insured_person(){
            let post_data = {
                insure_content_id: this.$store.getters.insure_content_id,
                insured_person: this.insured_person,
            }
            axios.post(host_url + '/insure/select_insured_person/', post_data)
            .then(response => {
                var tmp = response.data
                if(tmp.status == 200){
                    this.get_insured_related()   
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        new_insured_person(){
            this.$store.dispatch('resetPerson')
            this.personal_data_id = -1 
            this.insure_related_id = -1
            this.add = true
        },
        get_insured_peple_list(){
            axios.get(host_url + '/insure/get_insured_peple_list/')
            .then(response => {
                var tmp = response.data
                if(tmp.status == 200){
                    this.insured_people_list = tmp.res
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        remove(item_id){
            let check = confirm('請問您確定要刪除此筆資料嘛')
            if(!check)
                return 
            let post_data = {
                insure_related_id: item_id,
            }
            axios.post(host_url + '/insure/remove_related/', post_data)
            .then(response => {
                var tmp = response.data
                if(tmp.status == 200)
                    this.get_insured_related()
                else
                    alert(tmp.msg)

                
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        edit(item){
            this.add = true
            //TODO 編輯
        },
        get_insured_related(){
            console.log('In get_insured_related')
            let post_data = {
                insure_content_id: this.$store.getters.insure_content_id,
            }
            axios.post(host_url + '/insure/get_insured_related/', post_data)
            .then(response => {
                var tmp = response.data
                console.log(tmp)
                if(tmp.status == 200){
                    this.get_insured_peple_list()
                    this.insuredPeple = tmp.insure_related_list
                }
                console.log(tmp)
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        selectInsured(item){
            this.insuredPerson = item
        }
    },  
}
</script>
<style scoped lang="scss">
.insuredCard{
  border:1px solid #aaa;
  width: 100%;
  // height: 100px;
  margin-bottom: 12px;
  h3{
    font-size: 18px;
    font-weight: bold;
  }
  &_icon{
      padding-right: 16px;
  }
}
</style>