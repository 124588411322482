<template>
<!-- 註冊條款同意 -->
    <v-container>
        <!-- from: {{$store.getters.insure_from}} -->
        <v-row class="mt-3" align="center">
            <v-col cols="1">
            <v-icon @click="$router.push('/signUp')" large>mdi-chevron-left</v-icon>
            </v-col>
            <v-col>
            <div class="h2 text-center">同意條款</div> 
            </v-col>
            <v-col cols="1">
            </v-col>
        </v-row>
        <!-- todo: tmp -->
        <v-skeleton-loader v-bind="attrs" type="article,list-item-three-line,article,list-item-three-line,article,list-item-three-line"></v-skeleton-loader>
        <a href="http://test.mib.com.tw/Attention.html?WebNum=12&LogOPNum=B000" @click="open_web('http://test.mib.com.tw/Attention.html?WebNum=12&LogOPNum=B000')" class="ml-1" target="_blank" style="color:#6d9adb; cursor: pointer;">投保注意事項</a>
        <v-checkbox v-model="agree" label="本人(要保人)同意以上條款" hide-details class="ml-1 pt-0 mt-0 "></v-checkbox>
        <v-checkbox v-model="agree2" label="本人(要保人)已詳閱投保注意事項" hide-details class="ml-1 mb-4 pt-0 mt-0 "></v-checkbox>
        
        <div class="text-center">
            <v-btn @click="agree_submit" :disabled="!agree || !agree2" color="primary" depressed style="width:80%;">確認要保人資料</v-btn>
        </div>
    </v-container>
</template>
<script>
var host_url = process.env.VUE_APP_HOST_URL
export default {
    data(){
        return{
            attrs:{
                boilerplate: true,
            },
            agree: false,
            agree2: false,
        }
    },
    methods:{
        open_web(url){
            window.open(url, '_blank');
        },
        agree_submit(){
            axios.get(host_url + '/insure/agree_submit/')
            .then(response => {
                var tmp = response.data
                console.log('agree_submit:', tmp)
                if(tmp.status==200){
                    let from = this.$store.getters.insure_from
                    this.$store.commit('setProfile', tmp.profile)

                    if(from == 'insure')
                        this.$router.push('/insure/')
                    if(from == 'home')
                        this.$router.push('/')
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
    }
}
</script>
<style scoped>

</style>