import Vue from 'vue'
import Vuex from 'vuex'
import form from './modules/form';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        insure_content_id: -1,
        // 1: 投保進來  2：richmenu 查詢進來  
        insure_from: '321',
        insure_code: '',

        insure_id: -1,
        profile: {name: '', picture_url: ''},

        insure_check_agree1: false,
        insure_check_agree2: false,

        // 
        insure_data_check_related_person_status: false,


        insure_status: -1,
    },
    mutations: {
        setInsureContentId:(state, tmp) => (state.insure_content_id = tmp),
        setInsureFrom:(state, tmp) => (state.insure_from = tmp),
        setInsureId:(state, insure_id) => (state.insure_id = insure_id),
        setProfile:(state, tmp) => (state.profile = tmp),
        setInsureCode:(state, tmp) => (state.insure_code = tmp),
        
        setInsureCheckAgree1:(state, tmp) => (state.insure_check_agree1 = tmp),
        setInsureCheckAgree2:(state, tmp) => (state.insure_check_agree2 = tmp),

        setInsureStatus:(state, tmp) => (state.insure_status = tmp),
        
        setInsureDataCheckRelatedPersonStatus:(state, tmp) => (state.insure_data_check_related_person_status = tmp),
    },
    getters: {
        insure_content_id: (state) => (state.insure_content_id),
        insure_from: (state) => (state.insure_from),
        insure_id: (state) => (state.insure_id),
        profile: (state) => (state.profile),
        insure_code: (state) => (state.insure_code),

        insure_check_agree1: (state) => (state.insure_check_agree1),
        insure_check_agree2: (state) => (state.insure_check_agree2),

        insure_status: (state) => (state.insure_status),
        
        insure_data_check_related_person_status: (state) => (state.insure_data_check_related_person_status),
    },
    actions: {
    },
    modules: {
        form,
    }
})
