<template>
<!-- 註冊資料填寫 -->
    <v-container class="wrap">
        <h2 class="h2 my-3">基本資料</h2>
        <insure-form></insure-form>
        <v-btn @click="save" color="primary" depressed style="width:80%;">下一步：同意條款</v-btn>
    </v-container>
</template>

<script>
var host_url = process.env.VUE_APP_HOST_URL
import InsureForm from '../components/InsureForm.vue'
import { mapGetters,mapMutations,mapActions } from 'vuex'

export default {
    components:{InsureForm},
    data(){
        return{

        }
    },
    computed:{
        ...mapGetters([
        'idNum',
        'password',
        'chName',
        'enName',
        'activePicker',
        'birth',
        'gender',
        'country',
        'otherCountry',
        'mobile',
        'tel',

        'city',
        'area',
        'address',
        'year',
        'month',
        'date',
        'years',
        'months',
        'dates',

        'insure_content_id',
        ]),
    },
    methods:{
        save(){
            console.log('In save_personal_data ~~~ signup')
            let post_data = {
                signup: true,
                personal_data: {
                    'chName': this.chName,
                    'enName': this.enName,
                    'idNum': this.idNum,

                    'year': this.year,
                    'month': this.month,
                    'date': this.date,

                    'gender': this.gender,
                    'country': this.country,

                    'mobile': this.mobile,
                    'tel': this.tel,

                    'city': this.city,
                    'area': this.area,
                    'address': this.address
                },
            }
            axios.post(host_url + '/insure/save_personal_data/', post_data)
            .then(response => {
                var tmp = response.data
                if(tmp.status == 200){
                    this.nextStep()
                }
                console.log(tmp)
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        nextStep(){
            //todo: check
            this.$router.push('/terms')
        }
    }
}
</script>
<style scoped lang="scss">
.wrap{
    text-align: center;
    padding: 16px;
    max-width: 900px;
}
</style>