<template>
    <!-- 投保表單 -->
    <div class="form-wrap">
        <!-- InsureForm -->
        <v-text-field :value="chName" @input="setChName" single-line dense outlined>
        <template v-slot:prepend>
            <div class="nowrap mt-1 preText" @click="set_test_value">姓名&emsp;&emsp;&emsp;</div>
        </template>
        </v-text-field>
        <v-text-field :value="enName" @input="setEnName" single-line dense outlined hint="需與護照姓名相同">
        <template v-slot:prepend>
            <div class="nowrap mt-1 preText">英文姓名&emsp;</div>
        </template>
        </v-text-field>
        <v-text-field :value="idNum" @input="setIdNum" single-line dense outlined>
        <template v-slot:prepend>
            <div class="nowrap mt-1 preText">身份證字號</div>
        </template>
        </v-text-field>
        <p class="nowrap preText text-left">出生年月日</p>
        <div class="d-flex align-baseline mb-3">
        <v-select
            @input="setY"
            :items="years"
            :value="year"
            hide-details
            outlined
            dense
        >
            <template v-slot:prepend>
            <div class="nowrap mt-1 preText-sub">民國</div>
            </template>
            <template v-slot:append-outer>
            <div class="nowrap mt-1 mr-1 preText-sub">年</div>
            </template>
        </v-select>
        <v-select
            @input="setM"
            :items="months"
            :value="month"
            hide-details
            outlined
            dense
        >
            <template v-slot:append-outer>
            <div class="nowrap mt-1 mr-1 preText-sub">月</div>
            </template>
        </v-select>
        <v-select
            @input="setDate"
            :items="dates"
            :value="date"
            hide-details
            outlined
            dense
            :disabled="!year && !month"
        >
            <template v-slot:append-outer>
            <div class="nowrap mt-1 mr-1 preText-sub">日</div>
            </template>
        </v-select>

        </div>

        <div class="d-flex align-baseline">
        <p class="nowrap preText text-left">性別</p>
        <v-radio-group
            :value="gender"
            @change="setGender"
            row
            dense
            style="width: 260px"
            class="ml-2"
        >
            <v-radio
            value="male"
            >
            <template v-slot:label>
                <div class="preText-sub">男性</div>
            </template>
            </v-radio>
            <v-radio
            value="female"
            >
            <template v-slot:label>
                <div class="preText-sub">女性</div>
            </template>
            </v-radio>
        </v-radio-group>
        </div>
        <div class="d-flex align-baseline mb-5">
        <p class="nowrap preText text-left">國籍</p>
        <v-radio-group
            :value="country"
            @change="setCountry"
            row
            dense
            class="ml-2"
            style="width: 260px;"
        >
            <v-radio
            label="中華民國"
            value="中華民國"
            >
            <template v-slot:label>
                <div class="nowrap preText-sub">中華民國</div>
            </template>
            </v-radio>
            <v-radio
            :value="otherCountry"
            class="ma-0"
            >

            <template v-slot:label>
                <span class="mr-2 nowrap preText-sub">其他</span>
                <v-text-field :value="otherCountry" @input="setNation" :disabled="country=='中華民國'" single-line dense hide-details style="min-width:30px;max-width:70px;"></v-text-field>
            </template>
            </v-radio>
        </v-radio-group>
        </div>
        <v-text-field :value="mobile" @input="setMobile" type="tel" single-line dense outlined>
        <template v-slot:prepend>
            <div class="nowrap mt-1 preText">手機&emsp;&emsp;&emsp;</div>
        </template>
        </v-text-field>
        <v-text-field :value="tel" @input="setTel" type="tel" single-line dense outlined>
        <template v-slot:prepend>
            <div class="nowrap mt-1 preText">聯絡電話&emsp;</div>
        </template>
        </v-text-field>
        <p class="nowrap preText text-left">聯絡地址</p>

        <v-row class="align-baseline mb-3" no-gutters>
        <v-col cols="4">
            <v-select
            :items="cities"
            :value="city"
            @input="setCityLocal"
            hide-details
            outlined
            dense
            class="pr-1"
            >
            </v-select>
        </v-col>
        <v-col cols="4">
            <v-select
            :items="areas"
            :value="area"
            @input="setAreaLocal"
            hide-details
            outlined
            dense
            class="pr-1"
            >
            </v-select>
        </v-col>
        <v-col cols="4">
            <v-text-field :value="zip" hide-details single-line dense outlined background-color="#eee" readonly>
            </v-text-field>
        </v-col>
        </v-row>
        <div class="d-flex align-baseline">
        <!-- <p class="nowrap preText text-left"></p> -->
        <v-text-field :value="address" @input="setAddress" single-line dense outlined>
        </v-text-field>
        </div>
    </div>
</template>

<script>
import { mapGetters,mapMutations,mapActions } from 'vuex'
import addressJson from '../assets/json/address.json'
export default {
    name: 'InsureForm',
    props:{
    },
    data(){
        return{
            addressT: addressJson,
            cities: []
        }
    },
    computed:{
        ...mapGetters([
        'idNum',
        'password',
        'chName',
        'enName',
        'activePicker',
        'birth',
        'gender',
        'country',
        'otherCountry',
        'mobile',
        'tel',
        'year',
        'month',
        'date',
        'years',
        'months',
        'dates',
        
        'city',
        'area',
        'areas',
        'address',
        'zip',
        ]),
        // country:{
        //   get(){
        //     return this.$store.getters.country
        //   },
        //   set(val){
        //     this.$store.commit('setCountry',val)
        //   }
        // }
    },
    mounted(){
        // this.$store.dispatch('setDateData')
        this.setYearsMons();
        console.log('11111', this.city)

        this.setCities();
        this.resetAreas()
        // if(this.city != '')
        //     this.setAreas()
    },
    methods:{
        set_test_value(){
            // test value
            this.setChName('王小明')
            this.setEnName('yoyo')
            this.setIdNum('H124458746')

            this.setYear(80)
            this.setMonth(1)
            this.setM(1)
            this.setDate(1)

            this.setGender('male')
            this.setCountry('中華民國')

            this.setMobile('0912345678')
            this.setTel('0312645684')

            this.setCity('臺北市')
            this.resetAreas()
            this.setArea('中山區')

            this.setAddress('明水路1234號')
            this.setZip('104')
        },
        ...mapActions(['setYearsMons', 'resetAreas', 'resetZip']),
        ...mapMutations([
            'setYear',
            'setMonth',
            'setDate',
            'setDays',
            'setIdNum',
            'setPassword',
            'setChName',
            'setEnName',
            'setActivePicker',
            'setBirth',
            'setMenu',
            'setGender',
            'setCountry',
            'setOtherCountry',
            'setMobile',
            'setTel',
            'setCity',
            'setArea',
            'setAddress',
            'setZip',
        ]),
        //setData
        //--setData/time
        setY(val){
            this.setYear(val)
            if(this.month){
                this.setDays({year:this.year,month:this.month});
            }
        },
        setM(val){
            this.setMonth(val);
            if(this.year){
                this.setDays({year:this.year,month:this.month});
            }
        },
        setCityLocal(val){
            this.setCity(val)
            this.resetAreas()
        },
        setAreaLocal(val){
            console.log('In setAreaLocal')
            this.setArea(val)
            this.resetZip()
        },
        //--setData/place
        setCities(){
            this.addressT.forEach(el=>{
                if(!['南海島', '釣魚臺'].includes(el.City)){this.cities.push(el.City)}
            })
        },
        // setAreas(){
        //     this.setArea('')
        //     this.zip = ''
        //     this.areas = []
        //     console.log(this.city)
        //     console.log(this.city)
        //     console.log(this.city)

        //     this.addressT.forEach(el=>{
        //         if(this.city == el.City){
        //             this.areas.push(el.Area)
        //         }
        //     })
        // },
        // setZip(){
        //     console.log("In setZIP")
        //     this.zip = this.addressT.find(el=>{
        //         // return this.setCity(el.City) && this.setArea(el.Area)
        //         return el.City==this.city && el.Area==this.area
        //     }).Zip5.slice(0,3)

        //     // this.zip = this.addressT.find(e=>(e.City==this.city_ && e.Area==e.area_)).Zip5.slice(0,3)

        // },
        //form
        setNation(val){
            this.setOtherCountry(val)
            this.setCountry(val)
        },

    }
}
</script>
<style lang="scss" scoped>
.form-wrap{
  // max-width: 900px;
  margin: 0 auto;
  padding: 0 8px;
}
.preText{
  font-size: 14px;
  width: 70px;
  color: #000;
}
.preText-sub{
  font-size: 14px;
  // width: 50px;
  color: #777;
}
.v-input__slot{
  padding: 0 !important;
}
</style>