<template>
<!-- 投保小幫手個人首頁 -->
  <v-container class="wrap home_wrap">
    <!-- {{$store.getters.profile}} -->
    <v-avatar v-if="$store.getters.profile!=null" class="mx-auto mb-5" :color="$store.getters.profile.picture_url!=''?'':'primary'" size="100">
      <img v-if="$store.getters.profile.picture_url!=''" :src="$store.getters.profile.picture_url">
    </v-avatar>
    <h3 v-if="$store.getters.profile!=null" class="mb-10">HI，{{$store.getters.profile.name}}</h3>
    <div class="home_btn">
      <v-btn @click="$router.push('/tmpStorage')" class="py-7" color="primary" outlined>
        <v-row no-gutters>
          <v-icon class="ml-2 mt-1" left>mdi-application</v-icon>
          <v-col class="ml-10" style="text-align:left">
            暫存保單
          </v-col>
        </v-row>
    </v-btn>
    </div>
    <div class="home_btn">
      <v-btn @click="$router.push('/insuredList')" class="py-7" color="primary" outlined>
        <v-row no-gutters>
          <v-icon class="ml-2 mt-1" left>mdi-application</v-icon>
          <v-col class="ml-10" style="text-align:left">
            被保人資料
          </v-col>
        </v-row>
      </v-btn>
    </div>
    <div class="home_btn">
      <v-btn @click="$router.push('/record')" class="py-7" color="primary" outlined>
        <v-row no-gutters>
          <v-icon class="ml-2 mt-1" left>mdi-application</v-icon>
          <v-col class="ml-10" style="text-align:left">
            投保紀錄
          </v-col>
        </v-row>
      </v-btn>
    </div>
  </v-container>
    <!-- <v-container>
        <h1>This is Home</h1>
        <v-btn @click="test">test</v-btn>
        <v-btn @click="test">test</v-btn>
        <v-btn @click="test">test</v-btn>
    </v-container> -->

</template>

<script>
var host_url = process.env.VUE_APP_HOST_URL

export default {
    name: 'Home',
    components: {
    },
    created(){
        // alert('at home')
    },
    methods:{
        test(){
            // console.log(host_url+'/auth3/test')
            // axios.get(host_url+'/auth3/test')
            // .then(response => {
            //     var tmp = response.data
            //     console.log(tmp)
            // })
            // .catch((error)=> {
            //     console.log(error);
            // });
        },
    }
}
</script>
<style lang="scss" scoped>
.home{
  &_wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &_btn{
    width: 100%;
    margin-bottom: 16px;
    .v-btn{
      border: 1px solid #777;
      width: 70%;
    }
  }
  
}
</style>