<template>
    <v-app>
        <!-- todo : hide -->
        <!-- <div>
        <v-app-bar color="primary">
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$router.push('/login')">
            <v-icon>mdi-account-circle</v-icon>
            </v-btn>
        </v-app-bar>
        </div> -->
        <v-main>
        <router-view/>
        </v-main>
    </v-app>
</template>
<script>
var host_url = process.env.VUE_APP_HOST_URL

let params = new URLSearchParams(window.location.search.substring(1));
if (params.get('vconsole') == "1") {
    let vConsoleScript = document.createElement('script');
    vConsoleScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/vConsole/3.4.0/vconsole.min.js');
    document.head.appendChild(vConsoleScript);

    vConsoleScript.onload = function () {
        var vConsole = new VConsole();
    };
}

export default {
    name: 'App',

    data: () => ({
      //
    }),
    moutned(){
        alert('App mounted')
    },
    methods:{
        test(){
            console.log(host_url+'/auth3/test')
            axios.get(host_url+'/auth3/test')
            .then(response => {
                var tmp = response.data
                console.log(tmp)
            })
            .catch((error)=> {
                console.log(error);
            });
        },
    },
};
</script>
<style lang="scss">
@import "assets/scss/main.scss";
</style>
