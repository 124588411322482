<template>
<!-- 投保進度條 -->
    <div>
        <v-stepper v-model="childStep" @change="$emit('turn',childStep)" flat vertical class="d-flex align-center pb-2">
                <v-stepper-step
                class="pa-0 pr-2"
                step="1"
                editable
                >
                <div v-if="childStep==1" class="caption">
                要保人資料
                </div>
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                class="pa-0 px-2"
                step="2"
                editable
                >
                <div v-if="childStep==2" class="caption">
                被保人資料
                </div>
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                class="pa-0 px-2"
                step="3"
                editable
                >
                <div v-if="childStep==3" class="caption">
                投保內容
                </div>
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step
                class="pa-0 pl-2"
                step="4"
                editable
                >
                <div v-if="childStep==4" class="caption">
                確認保單資料
                </div>
                </v-stepper-step>
        </v-stepper>
    </div>
</template>
<script>
export default {
    props:['step'],
    watch:{
        step(val){
            this.childStep = val
        }
    },
    data(){
        return{
            childStep: this.step
        }
    },
    methods:{
        // goPage(){
        //     let page = ''
        //     switch(this.step){
        //         case 1:
        //             page = '/gurantor'
        //             break;
        //         case 2:
        //             page = '/gurantor2'
        //             break;
        //         case 3:
        //             page = '/gurantor3'
        //             break;
        //     }
        //     this.$router.push(page)
        // },
    }
}
</script>
<style scoped>

</style>