<template>
    <!-- 投保step4 -->
    <div class="insData_wrap">
        <div class="insData_title">
            <v-icon class="mr-2" color="primary">mdi-check-circle-outline</v-icon>
            <h4 class="primary--text pt-1">旅遊資料</h4>
        </div>
        <div class="insData_content">
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">旅遊期間</v-col>
                <v-col>{{travel_info['3']}}起 至{{travel_info['4']}}止</v-col>
            </v-row>
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">保險天數</v-col>
                <v-col>{{travel_info['dt']}}天(不足一天以一天計算)</v-col>
            </v-row>
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">旅遊目的</v-col>
                <v-col>{{travel_info['0']}}</v-col>
            </v-row>
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">旅遊方式</v-col>
                <v-col>{{travel_info['1']}}</v-col>
            </v-row>
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">目的地</v-col>
                <v-col>{{travel_info['2']}}</v-col>
            </v-row>
        </div>

        <div class="insData_title">
            <v-icon class="mr-2" color="primary">mdi-check-circle-outline</v-icon>
            <h4 class="primary--text pt-1">投保內容</h4>
        </div>
        <div class="insData_content">
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">身故或失能保險金</v-col>
                <v-col>{{content.add_value}}</v-col>
            </v-row>
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">傷害醫療保險金</v-col>
                <v-col>{{content.mr_value}}</v-col>
            </v-row>
        </div>

        <div class="insData_title">
            <v-icon class="mr-2" color="primary">mdi-check-circle-outline</v-icon>
            <h4 class="primary--text pt-1">要保人</h4>
        </div>
        <div class="insData_content">
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">姓名</v-col>
                <v-col>{{proposer_personal_data.name}}</v-col>
            </v-row>
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">身份證字號</v-col>
                <v-col>{{proposer_personal_data.idNum}}</v-col>
            </v-row>
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">出生年月日</v-col>
                <v-col>{{proposer_personal_data.year}}/{{proposer_personal_data.month}}/{{proposer_personal_data.date}}</v-col>
            </v-row>
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">性別</v-col>
                <v-col>{{proposer_personal_data.gender}}</v-col>
            </v-row>
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">國籍</v-col>
                <v-col>{{proposer_personal_data.country}}</v-col>
            </v-row>
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">手機</v-col>
                <v-col>{{proposer_personal_data.mobile}}</v-col>
            </v-row>
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">聯絡電話</v-col>
                <v-col>{{proposer_personal_data.tel}}</v-col>
            </v-row>
            <v-row no-gutters class="mb-1">
                <v-col cols="5" class="insTitle">聯絡地址</v-col>
                <v-col>{{proposer_personal_data.address}}</v-col>
            </v-row>
        </div>

        <div v-for="person, idx in insured_person_list" :key="idx">
            <div class="insData_title">
                <v-icon class="mr-2" :color="person.status==1?'primary':'error'" >{{person.status==1?'mdi-check-circle-outline':'mdi-alert-circle-outline'}}</v-icon>
                <h4 :class="person.status==1?'primary--text':'error--text '" class="pt-1">被保人{{idx+1}}</h4>
            </div>
            <div class="insData_content">
                <v-row no-gutters class="mb-1">
                    <v-col cols="5" class="insTitle">姓名{{person.status}}</v-col>
                    <v-col>{{person.name}}</v-col>
                </v-row>
                <v-row no-gutters class="mb-1">
                    <v-col cols="5" class="insTitle">身份證字號</v-col>
                    <v-col>{{person.idNum}}</v-col>
                </v-row>
                <v-row no-gutters class="mb-1">
                    <v-col cols="5" class="insTitle">出生年月日</v-col>
                    <v-col>{{person.year}}/{{person.month}}/{{person.date}}</v-col>
                </v-row>
                <v-row no-gutters class="mb-1">
                    <v-col cols="5" class="insTitle">性別</v-col>
                    <v-col>{{person.gender}}</v-col>
                </v-row>
                <v-row no-gutters class="mb-1">
                    <v-col cols="5" class="insTitle">國籍</v-col>
                    <v-col>{{person.country}}</v-col>
                </v-row>
                <v-row no-gutters class="mb-1">
                    <v-col cols="5" class="insTitle">手機</v-col>
                    <v-col>{{person.mobile}}</v-col>
                </v-row>
                <v-row no-gutters class="mb-1">
                    <v-col cols="5" class="insTitle">聯絡電話</v-col>
                    <v-col>{{person.tel}}</v-col>
                </v-row>
                <v-row no-gutters class="mb-1">
                    <v-col cols="5" class="insTitle">聯絡地址</v-col>
                    <v-col>{{person.address}}</v-col>
                </v-row>
                <span v-if="person.qdict!=undefined &&  person.qdict.benefitPerson==1" >
                    <span v-for="inheit, idx2 in person.heirList" :key="idx2">
                        <v-row no-gutters class="mb-1">
                            <v-col cols="5" class="insTitle" style="color: #229e80;">身故受益人</v-col>
                            <v-col>{{inheit.name}}</v-col>
                        </v-row>
                        <v-row no-gutters class="mb-1">
                            <v-col cols="5" class="insTitle">被保人關係</v-col>
                            <v-col>{{inheit.related}}</v-col>
                        </v-row>
                        <v-row no-gutters class="mb-1">
                            <v-col cols="5" class="insTitle">身份證字號</v-col>
                            <v-col>{{inheit.idNum}}</v-col>
                        </v-row>
                        <v-row no-gutters class="mb-1">
                            <v-col cols="5" class="insTitle">受益人電話</v-col>
                            <v-col>{{inheit.tel}}</v-col>
                        </v-row>
                        <v-row no-gutters class="mb-1">
                            <v-col cols="5" class="insTitle">受益人地址</v-col>
                            <v-col>{{inheit.address}}</v-col>
                        </v-row>
                        <v-row no-gutters class="mb-1">
                            <v-col cols="5" class="insTitle">繼承順序</v-col>
                            <v-col>{{inheit.inheit_order}}</v-col>
                        </v-row>
                        <v-row no-gutters class="mb-1">
                            <v-col cols="5" class="insTitle">繼承比例</v-col>
                            <v-col>{{inheit.ratio}}</v-col>
                        </v-row>
                    </span>
                </span>
                <span v-if="person.qdict!=undefined &&  person.qdict.benefitPerson==2" >
                    <v-row no-gutters class="mb-1">
                        <v-col cols="5" class="insTitle" style="color: #229e80;">身故受益人</v-col>
                        <v-col>法定繼承人</v-col>
                    </v-row>
                </span>

                <v-row v-if="person.qdict!=undefined && person.qdict.ans1=='yes'" no-gutters class="mb-1">
                    <v-col cols="5" class="insTitle">投保他家旅平險</v-col>
                    <v-col>{{person.qdict.ans1_company}} {{person.qdict.ans1_money}}</v-col>
                </v-row>
                <v-row v-if="person.qdict!=undefined &&  person.qdict.ans2=='yes'" no-gutters class="mb-1">
                    <v-col cols="5" class="insTitle">投保他家醫療傷害險</v-col>
                    <v-col>{{person.qdict.ans2_company}} {{person.qdict.ans2_money}}</v-col>
                </v-row>
                <v-row v-if="person.qdict!=undefined" no-gutters class="mb-1">
                    <v-col cols="5" class="insTitle">是否受監護宣告</v-col>
                    <v-col>{{person.qdict.ans3}}</v-col>
                </v-row>
            </div>
        </div>
        <v-checkbox v-if="$store.getters.insure_status==0" v-model="agree1" @change="$store.commit('setInsureCheckAgree1', agree1)" label="本人(要保人)已受告知並了解所投保商品之重要內容及投保須知等相關事宜">
            <template v-slot:label>
                <span>本人(要保人)已受告知並了解所投保商品之重要內容及投保須知等相關事宜</span>
            </template>
        </v-checkbox>
        <v-checkbox v-if="$store.getters.insure_status==0" v-model="agree2" @change="$store.commit('setInsureCheckAgree2', agree2)" label="已閱讀" style="margin-top: -16px;">
            <template v-slot:label>
                <span>已閱讀</span>
                <a href="https://www.youtube.com" @click="open_web('https://www.youtube.com')" target="_blank" style="color:#6d9adb; cursor: pointer;"> 要(被)保險人聲明事項</a>
            </template>
        </v-checkbox>
    </div>
</template>
<script>
import insD1 from '../assets/json/insureData1.json'
var host_url = process.env.VUE_APP_HOST_URL

export default {
    data(){
        return{
            insD1,
            insured_person_list: [],
            agree1: false,
            agree2: false,

            detail: null,
            proposer_personal_data: {
                name: '',
                idNum: '',
                year: '',
                month: '',
                date: '',
                gender: '',
                country: '',
                mobile: '',
                tel: '',
                address: '',
            },
            travel_info: {
                "0": "",
                "1": "",
                "2": "",
                "3": "",
                "4": "",
                "5": "",
                "dt": "",
            },
            content: {add_value: '', mr_value: ''}
        }
    },
    mounted(){
        this.get_content_detail()
        this.get_insure_content_status()
    },
    methods: {
        get_insure_content_status(){
            let post_data = {insure_code: this.$store.getters.insure_code}
            axios.post(host_url + '/insure/get_insure_content_status/', post_data)
            .then(response => {
                var tmp = response.data
                console.log(tmp)
                if(tmp.status == 200){
                    this.$store.commit('setInsureStatus', tmp.insure_status)
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        open_web(url){
            window.open(url, '_blank');
        },
        get_content_detail(){
            let post_data = {
               insure_content_id : this.$store.getters.insure_content_id
            //    insure_content_id : 15
            }
            axios.post(host_url + '/insure/get_content_detail/', post_data)
            .then(response => {
                var tmp = response.data
                console.log("tmp get_content_detail:")
                console.log(tmp)
                if(tmp.status == 200){
                    this.detail = tmp.detail
                    this.travel_info = tmp.detail.travel_info
                    this.content = tmp.detail.content

                    this.get_personal_data(1, this.detail.proposer_personal_data_id)

                    // related_id_list
                    for(let d of this.detail.insure_related_list)
                        this.insured_person_list.push({personal_data_id: d.personal_data_id, insure_related_id: d.insure_related_id, status: d.status})

                    for(let d of this.detail.insure_related_list){
                        this.get_personal_data(2, d.personal_data_id)
                        this.get_death_benefit(d.insure_related_id)
                    }

                    let check_related_status = true
                    for(let d of this.detail.insure_related_list)
                        if(!d.status)
                            check_related_status = false
                    
                    this.$store.commit('setInsureDataCheckRelatedPersonStatus', check_related_status)
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        // tar: 1 要保人  2 被保人
        get_personal_data(tar, id){
            let post_data = {
                personal_data_id: id
            }
            axios.post(host_url + '/insure/get_personal_data/', post_data)
            .then(response => {
                var tmp = response.data
                console.log("tmp:")
                console.log(tmp)
                if(tmp.status == 200){
                    if(tar == 1)
                        this.proposer_personal_data = tmp.person
                    else if(tar == 2){
                        let idx = this.insured_person_list.findIndex(e=>e.personal_data_id==id)
                        for(let key in tmp.person)
                            this.$set(this.insured_person_list[idx], key, tmp.person[key])
                    }
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        get_death_benefit(insure_related_id){
            let post_data = {
                insure_related_id: insure_related_id
            }
            axios.post(host_url + '/insure/get_death_benefit/', post_data)
            .then(response => {
                var tmp = response.data
                console.log("tmp:")
                console.log(tmp)
                if(tmp.status == 200){
                    let idx = this.insured_person_list.findIndex(e=>e.insure_related_id==insure_related_id)
                    this.$set(this.insured_person_list[idx], 'heirList', tmp.heirList)
                    this.$set(this.insured_person_list[idx], 'qdict', tmp.qdict)
                }
            })
            .catch((error)=> {
                console.log(error);
            });
        },

    },
}
</script>
<style lang="scss" scoped>
.insData{
    &_wrap{
        text-align: left;
        margin: 0 auto;
        padding: 0 8px;
    }
    &_title{
        display: flex;
        align-items: center;
    }
    &_content{
        font-size: 14px;
        padding-left: 32px;
        .insTitle{
            color: #828282;
            white-space: nowrap;
        }
    }
}
</style>