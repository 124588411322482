<template>
  <div>
      <!-- <h1>Login</h1>
      <v-btn @click="getLoginUrl()">Login</v-btn> -->
  </div>
</template>

<script>
var host_url = process.env.VUE_APP_HOST_URL;

export default {
    name: "Login",

    components: {},
    mounted () {
        let params = new URL(location.href).searchParams;
        if (params.get('code')) {
            this.login(params.get('code'));
        }
        else{
            localStorage.setItem('insure_id', params.get('i'))
            localStorage.setItem('insure_from', params.get('f'))
            localStorage.setItem('insure_code', params.get('c'))
            this.getLoginUrl()
        }
    },
    methods: {
        auth_link_check(){
            axios.get(host_url + '/insure/auth_link_check/')
            .then(response => {
                var tmp = response.data
                console.log("auth_link_check:", tmp)
                let insure_from = localStorage.getItem('insure_from')
                let insure_id = localStorage.getItem('insure_id')
                let insure_code = localStorage.getItem('insure_code')

                localStorage.removeItem('insure_from')
                localStorage.removeItem('insure_id')
                localStorage.removeItem('insure_code')

                this.$store.commit('setInsureFrom', insure_from)
                this.$store.commit('setInsureId', insure_id)
                this.$store.commit('setProfile', tmp.profile)
                this.$store.commit('setInsureCode', insure_code)
                
                if(tmp.status == 200){

                    if(insure_from == 'insure'){
                        this.$router.push('/insure')            
                    }
                    else if(insure_from == 'home'){
                        this.$router.push('/')
                    }
                }
                                
                else if(tmp.status == 202)
                    this.$router.push('/terms')
                else if(tmp.status == 201)
                    this.$router.push('/signup')
                
            })
            .catch((error)=> {
                console.log(error);
            });
        },
        async login(code) {
            try {
                let payload = {"code": code}
                console.log(payload)
                
                let resp = await axios.post(host_url + "/auth3/line/callback", payload);
                console.log(resp.data);

                this.auth_link_check()
            } catch(error) {
                alert('登入失敗，請再試一次');
                console.log(error);
                throw new Error('Login Fail')
            }  
        },
        async getLoginUrl() {
            try {
                let resp = await axios.get(host_url + "/auth3/line/login?format=json");
                console.log(resp.data.url);
                window.location.href = resp.data.url;
            } catch (error) {
                console.log(error);
                throw new Error('Login Fail')
            }
        }
    },
};
</script>
